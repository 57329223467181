<template>

  <!-- Content -->
  <div v-if="!isStyle">

    <div v-if="!selectedContentCategory" class="pb-5">
      <ws-text-field
          @input="$emit('input' , element.config)"
          :label="$t('avalon.form.name')"
          :placeholder="$t('avalon.form.enter_name')"
          v-model="element.config.name"
          avalon-style
      />
      <a-parameter-switch
          class="mt-5"
          @input="handleFormModal"
          label="avalon.form.is_button_title"
          v-model="element.config.is_button"
          tooltip="avalon.form.is_button_title"
          light
          color="var(--wsMIDDLE)"
      />
      <v-divider class="mt-4" :style="`border-color : ${wsDARKLIGHT}`" />
    </div>


    <block-settings-navigation
        v-model="selectedContentCategory"
        :items="sliderContentSelect"
        portal
    >
      <template #item.button>
        <ws-text-field
            @change="$emit('input' , element.config)"
            :label="$t('ButtonText')"
            :placeholder="$t('Send')"
            v-model="element.config.button_text"
            avalon-style
        />

        <v-sheet  :style="`border : 1px solid ${wsBACKGROUND}; border-radius : 8px`" class="mt-5">
          <!-- Primary Style-->
          <div @click="changeButtonStyle('primary')" class="pointer d-flex align-center py-4" >

            <ws-check-box
                @prevent="changeButtonStyle('primary')"
                prevent-select radio :selected-color="wsACCENT" :value="element.config.button_style === 'primary'"  :small="false" :color="wsACCENT" class="mr-1 ml-2" />
            <v-sheet
                :color="wsACCENT"
                class="d-flex align-center justify-center mr-3"
                min-width="84" min-height="42"
                dark
            >
              <h3 :style="`color : white`" >
                Ab
                <v-icon color="white" v-if="element.config.display_icon">
                  {{ element.config.icon }}
                </v-icon>
              </h3>
            </v-sheet>
            <h5 :style="`color : ${wsACCENT}`">{{ $t('PrimaryButton') }}</h5>
          </div>
          <v-divider :style="`border-color : ${wsBACKGROUND}`" />
          <!-- Secondary Style-->
          <div
              @click="changeButtonStyle('secondary')"
              class="pointer d-flex align-center py-4" >

            <ws-check-box
                :value="element.config.button_style === 'secondary' "
                :selected-color="wsACCENT"
                :color="wsACCENT"
                @prevent="changeButtonStyle('secondary')"
                :small="false"
                class="mr-1 ml-2"
                prevent-select radio
            />
            <v-sheet
                :style="`border : 2px solid ${wsACCENT}`"
                class="d-flex align-center justify-center mr-3"
                min-width="84" min-height="42"
            >
              <h3 :style="`color : ${wsACCENT}`">
                Ab
                <v-icon :color="wsACCENT" v-if="element.config.display_icon">
                  {{ element.config.icon }}
                </v-icon>
              </h3>
            </v-sheet>
            <h5 :style="`color : ${wsACCENT}`">{{ $t('SecondaryButton') }} </h5>
          </div>
          <v-divider :style="`border-color : ${wsBACKGROUND}`" />
          <!-- Text Style-->
          <div
              @click="changeButtonStyle('text')"
              class="pointer d-flex align-center py-4" >

            <ws-check-box
                @input="$emit('input' , element.config)"
                @prevent="changeButtonStyle('text')"
                :value="element.config.button_style === 'text' "
                :selected-color="wsACCENT"
                :color="wsACCENT"
                :small="false"
                prevent-select radio
                class="mr-1 ml-2"
            />
            <v-sheet
                color="transparent"
                class="d-flex align-center mr-3"
                min-width="84" min-height="42"
            >
              <h3 :style="`color : ${wsACCENT}`" class="text-no-wrap">Ab
                <v-icon :color="wsACCENT" v-if="element.config.display_icon">
                  {{ element.config.icon }}
                </v-icon>
              </h3>
            </v-sheet>
            <h5 :style="`color : ${wsACCENT}`">{{ $t('TextButton') }} </h5>
          </div>
        </v-sheet>

      </template>
      <template #item.other>
        <ws-text-field
            v-if="element.config.is_button"
            @change="$emit('input' , element.config)"
            :label="$t('avalon.form.dialog_title_edit')"
            :placeholder="$t('avalon.form.dialog_title_edit_placeholder')"
            v-model="element.config.dialog_title"

        />

        <ws-text-field
            @change="$emit('input' , element.config)"
            class="mt-5"
            :label="$t('avalon.form.completion_text')"
            :placeholder="$t('avalon.form.completion_text_placeholder')"
            v-model="element.config.completion_text"
            area
        />

      </template>

    </block-settings-navigation>



    <div v-if="false">

      <div v-if="element.config.has_labels">
        <h5 class="mt-4" >{{ $t('avalon.form.labels_color') }}</h5>
        <a-color-picker
            width="100%"
            @input="editElement"
            v-model="element.config.label_color"
            class="mt-2"
        />
      </div>

      <ws-switch @input="editElement" v-model="element.config.has_labels" :placeholder="$t('avalon.form.has_labels')" />


      <div v-if="element.config.type === 'lead'" >

        <h5 class="my-4" >{{ $t('avalon.form.fields') }}</h5>
        <ws-check-box :value="element.config.fields.includes('name')"    @input="formLeadChange($event , 'name' )" :label="$t('Name')" />
        <ws-check-box :value="element.config.fields.includes('phone')"   @input="formLeadChange($event , 'phone' )" :label="$t('Phone')" />
        <ws-check-box :value="element.config.fields.includes('email')"   @input="formLeadChange($event , 'email' )" :label="$t('Email')" />
        <ws-check-box :value="element.config.fields.includes('comment')" @input="formLeadChange($event , 'comment' )" :label="$t('Comment')" />
      </div>
    </div>

  </div>
  <!--  Style Parameters-->
  <div v-else>

    <a-parameter-slider
        v-if="element.config.style !== 'text'"
        @change="$emit('input' , element.config)"
        v-model="element.config.button_rounded"
        :default="0"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        label="ButtonRounded"
        light
        min="0"
        max="50"
        class="mb-5 mt-n4"
    />

    <ws-accordion
        :items="styleNavigationSelect"
    >
      <template #item.color>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            :block-style="blockStyle"
        />
      </template>
      <template #item.font>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            fonts
        />
      </template>
    </ws-accordion>



  </div>
</template>

<script>

import elementStyleSettings from "@/components/AvalonEditor/Dialogs/elements/elementStyleSettings";
import blockSettingsNavigation from "@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation";
export default {
  name: "avalonTextSettings",
  components : {
    blockSettingsNavigation,
    elementStyleSettings
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      selectedContentCategory : null,
      config : {}
    }
  },
  computed : {
    styleNavigationSelect() {
      return [
        { name : this.$t('Color') , value : 'color' },
        { name : this.$t('Fonts') , value : 'font' }
      ]
    },

    sliderContentSelect() {
      return [
        { text: this.$t('Button'), value: 'button' },
        { text: this.$t('Additional'), value: 'other' }
      ]
    },
    orientationSelect() {
      return [
        { text : this.$t('HorizontalLine') , value : false },
        { text : this.$t('VerticalLine') , value : true },
      ]
    }
  },
  methods : {
    handleFormModal(value) {

      if ( value ) {
        this.element.y2 = this.element.y1 + 1
        this.element.x2 = this.element.x1 + 6
      } else {
        this.element.y2 = this.element.y1 + 14
        this.element.x2 = this.element.x1 + 7
      }
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config) )
      this.$emit('update-element' , this.COPY(this.element))

    },
    updateSettings() {
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
    changeButtonStyle(value) {
      this.element.config.button_style = value
      this.element = this.COPY(this.element)
      this.$emit('input' , this.element.config)
    },
  },
  mounted() {
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>
</style>